// This file was automatically generated from concierge.nightlife.landing.soy.
// Please don't edit this file by hand.

if (typeof Nightloop == 'undefined') { var Nightloop = {}; }
if (typeof Nightloop.Templates == 'undefined') { Nightloop.Templates = {}; }
if (typeof Nightloop.Templates.Concierge == 'undefined') { Nightloop.Templates.Concierge = {}; }
if (typeof Nightloop.Templates.Concierge.Nightlife == 'undefined') { Nightloop.Templates.Concierge.Nightlife = {}; }


Nightloop.Templates.Concierge.Nightlife.Landing = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  Nightloop.Templates.Concierge.Layout(soy.$$augmentData(opt_data, {DynamicTemplate: 'Nightloop.Templates.Concierge.Nightlife.LandingBit', full_width: true}), output);
  return opt_sb ? '' : output.toString();
};


Nightloop.Templates.Concierge.Nightlife.LandingBit = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  output.append('<div id="search-container"><div id="left-filters" class="float-left"><div><div id="header">Make a Nightlife Reservation</div><div id="search-box"><form id="search-form" class="ajaxify" method="GET" action="', soy.$$escapeHtml(opt_data.concierge.base_url), '/nightlife/search"><div class="search-input"><input id="search-query-input" type="text" name="search_query" placeholder="Name or keywords (optional)" value="" /></div><div class="search-input"><select name=\'', soy.$$escapeHtml(opt_data.content.form.fields.municipality.name), '\'>');
  var muniList293 = opt_data.content.municipalities;
  var muniListLen293 = muniList293.length;
  for (var muniIndex293 = 0; muniIndex293 < muniListLen293; muniIndex293++) {
    var muniData293 = muniList293[muniIndex293];
    output.append('<option ', (opt_data.content.muni_id == muniData293[0]) ? 'selected' : '', ' value="', soy.$$escapeHtml(muniData293[0]), '">', soy.$$escapeHtml(muniData293[1]), ', ', soy.$$escapeHtml(muniData293[2]), '</option>');
  }
  output.append('</select></div><div id="search-date-select" class="search-input"><div id="search-date-display">Tonight</div><input id="search-date-input" type="hidden" class="datepicker sans" name="date-localized" value="', soy.$$escapeHtml(opt_data.content.date_formatted), '"><input id="search-date-input-submit" type="hidden" name="', soy.$$escapeHtml(opt_data.content.form.fields.date.name), '" value="', soy.$$escapeHtml(opt_data.content.date_formatted_default), '"><div class="float-end"></div></div><div class="search-input"><select id="home-partysize-select" name="', soy.$$escapeHtml(opt_data.content.form.fields.max_guests.name), '">');
  var choiceList315 = opt_data.content.form.fields.max_guests.choices;
  var choiceListLen315 = choiceList315.length;
  for (var choiceIndex315 = 0; choiceIndex315 < choiceListLen315; choiceIndex315++) {
    var choiceData315 = choiceList315[choiceIndex315];
    output.append('<option class="number" value="', soy.$$escapeHtml(choiceData315[0]), '" ', (choiceData315[0] == opt_data.content.form.fields.max_guests.value) ? 'selected="selected"' : '', '>', soy.$$escapeHtml(choiceData315[1]), '</option>');
  }
  output.append('</select></div><div id="search-submit" class="search-button nightlife">SEARCH</div></form></div></div></div><div id="search-results" class="float-left"><div id="results"><div id="overlay-container" class="no-display"><div id="overlay" class="landing"><!--- <img src="', soy.$$escapeHtml(opt_data.MEDIA_URL), 'images/spinner.gif" alt="Loading..." /> ---></div></div><div id="instructions"><div class="float-left arrow-left"><img src="', soy.$$escapeHtml(opt_data.MEDIA_URL), 'images/left-gray-thin-arrow.png" /></div><div class="float-left">Search exclusive reservations, <a class="ajaxify" href="', soy.$$escapeHtml(opt_data.concierge.base_url), '/directory?venue_class=NIGHTLIFE">or browse all nightlife venues</a>.</div><div class="float-end"></div></div></div></div><div class="float-end"></div></div><script type="text/javascript">$(document).ready(function() {Pmp.Concierge.Nightlife.Search.initialize( \'', soy.$$escapeHtml(opt_data.concierge.base_url), '\', \'', soy.$$escapeHtml(opt_data.content.muni_today_date), '\', \'', soy.$$escapeHtml(opt_data.content.date_formatted_default), '\', 0, "', soy.$$escapeHtml(opt_data.concierge.locale), '" );});<\/script>');
  return opt_sb ? '' : output.toString();
};
