// This file was automatically generated from concierge.nightlife.search.soy.
// Please don't edit this file by hand.

if (typeof Nightloop == 'undefined') { var Nightloop = {}; }
if (typeof Nightloop.Templates == 'undefined') { Nightloop.Templates = {}; }
if (typeof Nightloop.Templates.Concierge == 'undefined') { Nightloop.Templates.Concierge = {}; }
if (typeof Nightloop.Templates.Concierge.Nightlife == 'undefined') { Nightloop.Templates.Concierge.Nightlife = {}; }


Nightloop.Templates.Concierge.Nightlife.Search = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  Nightloop.Templates.Concierge.Layout(soy.$$augmentData(opt_data, {DynamicTemplate: 'Nightloop.Templates.Concierge.Nightlife.SearchBit', full_width: true}), output);
  return opt_sb ? '' : output.toString();
};


Nightloop.Templates.Concierge.Nightlife.SearchBit = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  output.append('<div id="search-container"><div id="left-filters" class="float-left"><div><div id="header">Make a Nightlife Reservation</div><div id="search-box"><form id="search-form" class="ajaxify" method="GET" action="', soy.$$escapeHtml(opt_data.concierge.base_url), '/nightlife/search"><input id="dv-input" type="hidden" name="dv" value="', soy.$$escapeHtml(opt_data.content.dv), '"/><div class="search-input"><input id="search-query-input" type="text" name="search_query" placeholder="Name or keywords (optional)" value="', soy.$$escapeHtml(opt_data.content.search_query), '" /></div><div class="search-input"><select name=\'', soy.$$escapeHtml(opt_data.content.form.fields.municipality.name), '\'>');
  var muniList356 = opt_data.content.municipalities;
  var muniListLen356 = muniList356.length;
  for (var muniIndex356 = 0; muniIndex356 < muniListLen356; muniIndex356++) {
    var muniData356 = muniList356[muniIndex356];
    output.append('<option ', (opt_data.content.muni_id == muniData356[0]) ? 'selected' : '', ' value="', soy.$$escapeHtml(muniData356[0]), '">', soy.$$escapeHtml(muniData356[1]), ', ', soy.$$escapeHtml(muniData356[2]), '</option>');
  }
  output.append('</select></div><div id="search-date-select" class="search-input"><div id="search-date-display">', soy.$$escapeHtml(opt_data.content.date_formatted), '</div><input id="search-date-input" type="hidden" class="datepicker sans" name="date-localized" value="', soy.$$escapeHtml(opt_data.content.date_formatted), '"><input id="search-date-input-submit" type="hidden" name="', soy.$$escapeHtml(opt_data.content.form.fields.date.name), '" value="', soy.$$escapeHtml(opt_data.content.date_formatted_default), '"><div class="float-end"></div></div><div class="search-input"><select id="home-partysize-select" name="', soy.$$escapeHtml(opt_data.content.form.fields.max_guests.name), '">');
  var choiceList380 = opt_data.content.form.fields.max_guests.choices;
  var choiceListLen380 = choiceList380.length;
  for (var choiceIndex380 = 0; choiceIndex380 < choiceListLen380; choiceIndex380++) {
    var choiceData380 = choiceList380[choiceIndex380];
    output.append('<option class="number" value="', soy.$$escapeHtml(choiceData380[0]), '" ', (choiceData380[0] == opt_data.content.form.fields.max_guests.value) ? 'selected="selected"' : '', '>', soy.$$escapeHtml(choiceData380[1]), '</option>');
  }
  output.append('</select></div><div id="search-submit" class="search-button nightlife">SEARCH</div></form></div>');
  Nightloop.Base.NeighborhoodFilter({neighborhoods: opt_data.content.neighborhoods}, output);
  Nightloop.Base.CategoryFilter({venue_class: 'NIGHTLIFE', categories: opt_data.content.categories}, output);
  Nightloop.Base.AtmosphereFilter({atmospheres: opt_data.content.atmospheres}, output);
  output.append('</div></div><div id="search-results" class="float-left"><div id="top-panel"><div class="matches float-left">', soy.$$escapeHtml(opt_data.content.search_numavail), ' MATCH', (opt_data.content.search_numavail > 1 || opt_data.content.search_numavail == 0) ? 'ES' : '', ' FOUND</div><div class="view-mode float-right"><div id="list-view-link" class="float-left active">&nbsp;</div><div id="grid-view-link" class="float-left">&nbsp;</div><div class="float-end"></div></div><div class="float-end"></div></div><div id="results"><div id="overlay-container" class="no-display"><div id="overlay"><!--- <img src="', soy.$$escapeHtml(opt_data.MEDIA_URL), 'images/spinner.gif" alt="Loading..." /> ----></div></div>');
  if (! opt_data.content.has_venue_access_in_muni) {
    output.append('<div class="subtext">You currently do not have access to any nightlife in this city.<br/><br/></div>');
  } else {
    output.append('<div id="list-view-results">');
    var resultList413 = opt_data.content.search_results;
    var resultListLen413 = resultList413.length;
    if (resultListLen413 > 0) {
      for (var resultIndex413 = 0; resultIndex413 < resultListLen413; resultIndex413++) {
        var resultData413 = resultList413[resultIndex413];
        Nightloop.Templates.Concierge.Nightlife.VenueSearchResultList({MEDIA_URL: opt_data.MEDIA_URL, venue_name: soy.$$escapeHtml(resultData413.venue.name), venue_neighborhood: soy.$$escapeHtml(resultData413.venue.neighborhood.name), venue_id: resultData413.venue.url_key_or_id, neighborhood_id: resultData413.venue.neighborhood.id, has_perks: resultData413.venue.has_perks, cutoff_time_display: resultData413.cutoff_time_display, result: resultData413, concierge: opt_data.concierge, reward: resultData413.venue.rewards_display, date_formatted_default: opt_data.content.date_formatted_default, search_query: opt_data.content.search_query}, output);
      }
    } else {
      output.append('<div class="no-results">There are no results matching your search criteria</div>');
    }
    output.append('</div><div id="grid-view-results" class="no-display">');
    var resultList433 = opt_data.content.search_results;
    var resultListLen433 = resultList433.length;
    if (resultListLen433 > 0) {
      for (var resultIndex433 = 0; resultIndex433 < resultListLen433; resultIndex433++) {
        var resultData433 = resultList433[resultIndex433];
        Nightloop.Templates.Concierge.Nightlife.VenueSearchResult({MEDIA_URL: opt_data.MEDIA_URL, venue_photos: resultData433.venue.photos, venue_name: soy.$$escapeHtml(resultData433.venue.name), venue_neighborhood: soy.$$escapeHtml(resultData433.venue.neighborhood.name), venue_id: resultData433.venue.url_key_or_id, neighborhood_id: resultData433.venue.neighborhood.id, has_perks: resultData433.venue.has_perks, cutoff_time_display: resultData433.cutoff_time_display, result: resultData433, concierge: opt_data.concierge, reward: resultData433.venue.rewards_display, date_formatted_default: opt_data.content.date_formatted_default, search_query: opt_data.content.search_query}, output);
      }
    } else {
      output.append('<div class="no-results">There are no results matching your search criteria</div>');
    }
    output.append('<div class="float-end"></div></div>');
  }
  output.append('</div></div><div class="float-end"></div></div><script type="text/javascript">$(document).ready(function() {Pmp.Concierge.Nightlife.Search.initialize( \'', soy.$$escapeHtml(opt_data.concierge.base_url), '\', \'', soy.$$escapeHtml(opt_data.content.muni_today_date), '\', \'', soy.$$escapeHtml(opt_data.content.date_formatted_default), '\',', soy.$$escapeHtml(opt_data.content.search_numavail), ', "', soy.$$escapeHtml(opt_data.concierge.locale), '", "', soy.$$escapeHtml(opt_data.MEDIA_URL), '" );', (opt_data.content.dv == 'g') ? 'Pmp.Concierge.Common.SearchPage._showGridView();' : '', 'Pmp.Concierge.Common.SearchPage._changeDate("', soy.$$escapeHtml(opt_data.concierge.locale), '");});<\/script>');
  return opt_sb ? '' : output.toString();
};


Nightloop.Templates.Concierge.Nightlife.FilterItem = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  output.append('<div class="filter-item"><div class="desc ellipsis float-left"><label class=\'neighborhood-select-label\'><input type=checkbox class="', (opt_data.isAll) ? 'isall' : '', '" ', (opt_data.checked) ? 'checked="checked"' : '', '/><input type=hidden class="filter-item-val', (opt_data.isAll) ? '-all' : '', '" value=\'', soy.$$escapeHtml(opt_data.val), '\' /> ', opt_data.name, '</label></div><div class="count float-left">', (opt_data.count) ? soy.$$escapeHtml(opt_data.count) : '&nbsp;', '</div><div class="float-end"></div></div>');
  return opt_sb ? '' : output.toString();
};


Nightloop.Templates.Concierge.Nightlife.VenueSearchResult = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  output.append('<div class="result-box float-left" venue_id="', soy.$$escapeHtml(opt_data.venue_id), '" neighborhood_id="', soy.$$escapeHtml(opt_data.neighborhood_id), '" category_keywords="', soy.$$escapeHtml(opt_data.result.venue.category_keywords_display), '" atmosphere_keywords="', soy.$$escapeHtml(opt_data.result.venue.atmosphere_keywords_display), '"><div class="photo venue-link">');
  if (opt_data.venue_photos) {
    var photoList510 = opt_data.venue_photos;
    var photoListLen510 = photoList510.length;
    for (var photoIndex510 = 0; photoIndex510 < photoListLen510; photoIndex510++) {
      var photoData510 = photoList510[photoIndex510];
      output.append((photoIndex510 == 0) ? '<img src="/.h/download/' + soy.$$escapeHtml(photoData510.medium) + '" width="339" />' : '');
    }
  } else {
    output.append('&nbsp;');
  }
  output.append('</div><div class="description"><div><a href="', soy.$$escapeHtml(opt_data.concierge.base_url), '/venue/', soy.$$escapeHtml(opt_data.venue_id), '?', soy.$$escapeHtml(opt_data.search_query), '" class="venue-name venue-link" target="_blank">', opt_data.venue_name, '</a></div><div class="venue-tags"><div class="ellipsis">', opt_data.venue_neighborhood, ' | ', opt_data.result.venue.category_keywords_display, '</div></div><div class="venue-tags-more ellipsis">', opt_data.result.venue.atmosphere_keywords_display, '</div>', (opt_data.cutoff_time_display) ? '<div class="venue-cutoff">' + soy.$$escapeHtml(opt_data.cutoff_time_display) + ' cutoff time</div>' : '', '<div>', (opt_data.has_perks) ? '<div class="venue-perk float-left">PERK</div>' : '', (opt_data.reward) ? '<div class="venue-rewards float-left">' + soy.$$escapeHtml(opt_data.reward) + '</div>' : '', '<div class="float-end"></div></div></div><div class="booking-options"><div><a class="custom-reservation-popup-link custom-request-btn nightlife" href="javascript:void(0);" date=', soy.$$escapeHtml(opt_data.date_formatted_default), ' venue_name="', opt_data.venue_name, '" venue_id="', soy.$$escapeHtml(opt_data.venue_id), '">request</a><div class="float-end"></div></div><div class="float-end"></div></div></div>');
  return opt_sb ? '' : output.toString();
};


Nightloop.Templates.Concierge.Nightlife.VenueSearchResultList = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  output.append('<div class="result-box list-view" venue_id="', soy.$$escapeHtml(opt_data.venue_id), '" neighborhood_id="', soy.$$escapeHtml(opt_data.neighborhood_id), '" category_keywords="', soy.$$escapeHtml(opt_data.result.venue.category_keywords_display), '" atmosphere_keywords="', soy.$$escapeHtml(opt_data.result.venue.atmosphere_keywords_display), '"><div class="description float-left"><div><a href="', soy.$$escapeHtml(opt_data.concierge.base_url), '/venue/', soy.$$escapeHtml(opt_data.venue_id), '?', soy.$$escapeHtml(opt_data.search_query), '" class="venue-name venue-link" target="_blank">', opt_data.venue_name, '</a></div><div class="venue-tags"><div class="ellipsis">', opt_data.venue_neighborhood, ' | ', opt_data.result.venue.category_keywords_display, '</div></div><div class="venue-tags-more ellipsis">', opt_data.result.venue.atmosphere_keywords_display, '</div>', (opt_data.cutoff_time_display) ? '<div class="venue-cutoff">' + soy.$$escapeHtml(opt_data.cutoff_time_display) + ' cutoff time</div>' : '', '<div>', (opt_data.has_perks) ? '<div class="venue-perk float-left">PERK</div>' : '', (opt_data.reward) ? '<div class="venue-rewards float-left">' + soy.$$escapeHtml(opt_data.reward) + '</div>' : '', '<div class="float-end"></div></div></div><div class="booking-options float-right"><div ><a class="custom-reservation-popup-link custom-request-btn nightlife" href="javascript:void(0);" date=', soy.$$escapeHtml(opt_data.date_formatted_default), ' venue_name="', opt_data.venue_name, '" venue_id="', soy.$$escapeHtml(opt_data.venue_id), '">request</a><div class="float-end"></div></div><div class="float-end"></div></div><div class="float-end"></div></div>');
  return opt_sb ? '' : output.toString();
};
