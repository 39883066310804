// This file was automatically generated from concierge.reservation.custom.soy.
// Please don't edit this file by hand.

if (typeof Nightloop == 'undefined') { var Nightloop = {}; }
if (typeof Nightloop.Templates == 'undefined') { Nightloop.Templates = {}; }
if (typeof Nightloop.Templates.Concierge == 'undefined') { Nightloop.Templates.Concierge = {}; }
if (typeof Nightloop.Templates.Concierge.Reservation == 'undefined') { Nightloop.Templates.Concierge.Reservation = {}; }


Nightloop.Templates.Concierge.Reservation.CustomPopup = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  Nightloop.Templates.Main.Popup.PopupLayout(soy.$$augmentData(opt_data, {div_id: 'custom-reservation-popup', header_html: 'Reservation Request', show_close_link: 'true', DynamicTemplate: 'Nightloop.Templates.Concierge.Reservation.CustomPopupBit'}), output);
  return opt_sb ? '' : output.toString();
};


Nightloop.Templates.Concierge.Reservation.CustomPopupBit = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  if (opt_data.is_complete) {
    output.append('<div id="custom-reservation-success-message" class="gold-message">Thank you. Your request has been submitted.</div><div class="body-block"><p><a class="close-main-colorbox-link" href="javascript:void(0);">Click here to close</a></p></div>');
  } else {
    output.append('<form id="custom-reservation-popup-form" method="POST" action="', soy.$$escapeHtml(opt_data.concierge.base_url), '/res/custom"><div class="body-block"><p>Hello ', soy.$$escapeHtml(opt_data.request.user.first_name), ',</p><div class="venue-selected-insns no-display"><p>Please provide us with some reservation details and we are happy to contact this venue on your behalf.</p></div><div class="no-venue-selected-insns no-display"><p>While we expand our venue community available for online booking, we\'re at your service for all your client\'s reservation needs at any venue in any city at any time. Didn\'t find a specific venue or need ideas for where to send clients?  No problem - just provide us with some details and we\'ll get back to you within 30 minutes.</p></div><div><div class="table-row"><div class="row-header float-left">First Name:</div><div class="row-content float-left"><input class="input-fname" type="text" name="first_name" ', (opt_data.content.first_name) ? ' value=\'' + soy.$$escapeHtml(opt_data.content.first_name) + '\'' : '', '/></div><div class="float-end"></div></div><div class="table-row"><div class="row-header float-left">Last Name:</div><div class="row-content float-left"><input class="input-lname" type="text" name="last_name" ', (opt_data.content.last_name) ? ' value=\'' + soy.$$escapeHtml(opt_data.content.last_name) + '\'' : '', '/></div><div class="float-end"></div><div class="table-row"><div class="row-header float-left">Phone Number*:</div><div class="row-content float-left"><input class="input-phone ');
    var errList921 = opt_data.err_types;
    var errListLen921 = errList921.length;
    for (var errIndex921 = 0; errIndex921 < errListLen921; errIndex921++) {
      var errData921 = errList921[errIndex921];
      output.append((errData921 == 'phone_number') ? 'error-outline' : '');
    }
    output.append('" type="text" name="phone_number" value=\'', (opt_data.content.phone_number) ? soy.$$escapeHtml(opt_data.content.phone_number) : '', '\'/></div><div class="float-end"></div></div></div><div class="table-row"><div class="row-header float-left">Venue(s):</div><div class="row-content float-left"><input class="input-venue" type="text" name="venue" ', (opt_data.content.venue) ? ' value=\'' + soy.$$escapeHtml(opt_data.content.venue) + '\'' : '', '/></div><div class="float-end"></div></div><div class="table-row"><div class="row-header float-left">Date*:</div><div class="row-content float-left"><input class="customres-datepicker input-date datepicker ');
    var errList937 = opt_data.err_types;
    var errListLen937 = errList937.length;
    for (var errIndex937 = 0; errIndex937 < errListLen937; errIndex937++) {
      var errData937 = errList937[errIndex937];
      output.append((errData937 == 'date') ? 'error-outline' : '');
    }
    output.append('" type="text" name="date-localized" ', (opt_data.content.date) ? ' value=\'' + soy.$$escapeHtml(opt_data.content.date) + '\'' : '', '/><input class="customres-datepicker-submit" type="hidden" name="date" ', (opt_data.content.date) ? ' value=\'' + soy.$$escapeHtml(opt_data.content.date) + '\'' : '', '/></div><div class="float-end"></div></div><div class="table-row"><div class="row-header float-left">Preferred Time*:</div><div class="row-content float-left"><input placeholder="7pm-8:30pm" class="customres-timepicker ');
    var errList955 = opt_data.err_types;
    var errListLen955 = errList955.length;
    for (var errIndex955 = 0; errIndex955 < errListLen955; errIndex955++) {
      var errData955 = errList955[errIndex955];
      output.append((errData955 == 'time') ? 'error-outline' : '');
    }
    output.append('" name="time" type="text" ', (opt_data.content.time) ? ' value=\'' + soy.$$escapeHtml(opt_data.content.time) + '\'' : '', '/></div><div class="float-end"></div></div><div class="table-row"><div class="row-header float-left">Party Size*:</div><div class="row-content float-left"><input class="input-partysize ');
    var errList967 = opt_data.err_types;
    var errListLen967 = errList967.length;
    for (var errIndex967 = 0; errIndex967 < errListLen967; errIndex967++) {
      var errData967 = errList967[errIndex967];
      output.append((errData967 == 'partysize') ? 'error-outline' : '');
    }
    output.append('" type="text" name="partysize" ', (opt_data.content.partysize) ? ' value=\'' + soy.$$escapeHtml(opt_data.content.partysize) + '\'' : '', '/>&nbsp;<div id="popup-gender-ratio">(<label><select class="number "  name="mf_ratio_male" id="select-mf-ratio-male"><option class="number" value="" selected=\'selected\'></option>');
    for (var i979 = 1; i979 < 51; i979++) {
      output.append((opt_data.content.mf_ratio_male) ? '<option class="number" value="' + soy.$$escapeHtml(i979) + '" ' + ((opt_data.content.mf_ratio_male == i979) ? 'selected="selected"' : '') + '>' + soy.$$escapeHtml(i979) + '</option>' : '<option class="number" value="' + soy.$$escapeHtml(i979) + '" >' + soy.$$escapeHtml(i979) + '</option>');
    }
    output.append('</select> M&nbsp;</label><label><select class="number " name="mf_ratio_female" id="select-mf-ratio-female"><option class="number" value="" selected=\'selected\'></option>');
    for (var i998 = 1; i998 < 51; i998++) {
      output.append((opt_data.content.mf_ratio_female) ? '<option class="number" value="' + soy.$$escapeHtml(i998) + '" ' + ((opt_data.content.mf_ratio_female == i998) ? 'selected="selected"' : '') + '>' + soy.$$escapeHtml(i998) + '</option>' : '<option class="number" value="' + soy.$$escapeHtml(i998) + '" >' + soy.$$escapeHtml(i998) + '</option>');
    }
    output.append('</select> F</label>)</div></div><div class="float-end"></div></div><div class="table-row"><div class="row-header float-left">Request Details:</div><div class="row-content float-left"><div class="input-container request-details-container"><div class="input-prompt request-details-prompt">type any details about the party and any special requests that make this reservation unique</div><textarea class="custom-reservation-textarea ');
    var errList1017 = opt_data.err_types;
    var errListLen1017 = errList1017.length;
    for (var errIndex1017 = 0; errIndex1017 < errListLen1017; errIndex1017++) {
      var errData1017 = errList1017[errIndex1017];
      output.append((errData1017 == 'custom_request') ? 'error-outline' : '');
    }
    output.append(' request-details" name="custom_request" maxlength="500">', (opt_data.content.custom_request) ? soy.$$escapeHtml(opt_data.content.custom_request) : '', '</textarea></div></div><div class="float-end"></div></div></div></div><div class="body-block edit-links"><div class="required-text float-left italics">*required</div><div class="float-right"><div class="link-next-to-button-div float-left"><a class="close-main-colorbox-link" href="javascript:void(0);">cancel</a></div> <a class="float-left" id="custom-reservation-submit-btn" href="javascript:void(0);">');
    Nightloop.Templates.Widget.GoldButton({text: 'SUBMIT REQUEST', size: 'medium'}, output);
    output.append('</a></div><div class="float-end"></div></div></form><script type="text/javascript">$(document).ready( function() {Pmp.Concierge.Reservation.Custom.bindClickHandlers();});<\/script>');
  }
  return opt_sb ? '' : output.toString();
};
